<template>
  <page-container class="plots-list" :title="$t('pages.titles.plotsList')">
    <v-form>
      <v-text-field v-model="filters.name" label="Идентификатор" dense filled background-color="white" />

      <v-autocomplete
        v-if="isAdmin"
        v-model="filters.user"
        label="Организация"
        :items="userOptions"
        multiple
        chips
        small-chips
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${filters.user.length} из ${userOptions.length}` }}
          </template>
        </template>
      </v-autocomplete>

      <v-select
        v-model="filters.waterBody"
        label="Акватория"
        :items="waterBodyOptions"
        multiple
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${filters.waterBody.length} из ${waterBodyOptions.length}` }}
          </template>
        </template>
      </v-select>

      <v-select
        v-model="filters.federalSubject"
        label="Регион"
        :items="federalSubjectOptions"
        multiple
        dense
        filled
        background-color="white"
      >
        <template #selection="{ index }">
          <template v-if="index === 0">
            {{ `выбрано ${filters.federalSubject.length} из ${federalSubjectOptions.length}` }}
          </template>
        </template>
      </v-select>
    </v-form>

    <v-data-table
      :headers="tableConfiguration"
      :items="filteredPlots"
      :options.sync="tableSettings"
      :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100] }"
      :loading="isPending"
      multi-sort
    >
      <template #item.name="{ value }">
        {{ plotPropertiesFormatters.name(value) }}
      </template>
      <template #item.area_ha="{ value }">
        {{ formatArea(value) }}
      </template>
      <template #item.status="{ value }">
        {{ $t(`aquarius.plotStatus.${value}`) }}
      </template>
      <template #item.user="{ value }">
        <a v-if="usersIndexByLogin[value]" :href="`/user/profile/${usersIndexByLogin[value].id}`" target="_blank">
          {{ getOrganizationName(value) }}
        </a>
        <span v-else :title="value">{{ getOrganizationName(value) }}</span>
      </template>
      <template #item.water_body="{ value }">
        {{ plotPropertiesFormatters.water_body(value) }}
      </template>
      <template #item.federal_subject="{ value }">
        {{ plotPropertiesFormatters.federal_subject(value) }}
      </template>
      <template #item.date="{ value }">
        {{ formatDateTime(value) }}
      </template>
      <template #item.actions="{ item }">
        <v-btn small icon @click="$bus.emit('printPlot', item)">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import { isoStringToDisplayDate } from 'geoportal/src/utils/time'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { AccountType, PlotStatus } from '@/enums/aquarius'
  import { EMPTY_VALUE_PLACEHOLDER } from '@/configurations/common'
  import { plotPropertiesPrecision, plotStatusesToOrder } from '@/configurations/aquarius'
  import { coalesceNilToEmptyString, formatNumber } from '@/utils/common'
  import { plotPropertiesFormatters } from '@/views/MapView/AsidePanel/PlotPanel/plotPanel'

  function formatDateTime(value) {
    return value ? isoStringToDisplayDate(value) : EMPTY_VALUE_PLACEHOLDER
  }

  const defaultTableSettings = {
    page: 1,
    itemsPerPage: 15,
    multiSort: true,
    sortBy: ['status'],
    sortDesc: [false]
  }
  const adminDefaultTableSettings = {
    ...defaultTableSettings,
    sortBy: ['status', 'date'],
    sortDesc: [false, true]
  }

  export default {
    name: 'PlotsListView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        plotPropertiesFormatters,

        filters: {
          waterBody: [],
          federalSubject: [],
          name: null,
          user: []
        },

        tableSettings: _.cloneDeep(defaultTableSettings),

        plots: [],
        users: []
      }
    },

    computed: {
      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      },

      tableConfiguration() {
        return [
          {
            value: 'name',
            text: 'Идентификатор',
            sort: (a, b) =>
              coalesceNilToEmptyString(a).localeCompare(coalesceNilToEmptyString(b), undefined, { numeric: true })
          },
          { value: 'area_ha', text: 'Площадь, га' },
          {
            value: 'status',
            text: 'Статус',
            sort: (a, b) => {
              a = plotStatusesToOrder[a] ?? plotStatusesToOrder[PlotStatus.UNKNOWN]
              b = plotStatusesToOrder[b] ?? plotStatusesToOrder[PlotStatus.UNKNOWN]
              return a - b
            }
          },
          this.isAdmin && {
            value: 'user',
            text: 'Организация',
            sort: (a, b) => {
              a = coalesceNilToEmptyString(this.getOrganizationName(a)).trim()
              b = coalesceNilToEmptyString(this.getOrganizationName(b)).trim()
              return coalesceNilToEmptyString(a).localeCompare(coalesceNilToEmptyString(b), undefined, {
                numeric: true
              })
            }
          },
          { value: 'water_body', text: 'Акватория' },
          { value: 'federal_subject', text: 'Регион' },
          this.isAdmin && { value: 'date', text: 'Дата создания участка' },
          { value: 'actions', sortable: false, align: 'center', width: 64 }
        ].filter(Boolean)
      },

      userOptions() {
        const options = this.users.map((user) => ({
          value: user.login,
          text: user.full_name ?? user.short_name ?? user.login
        }))
        return _.orderBy(options, ['text'], ['asc'])
      },

      usersIndexByLogin() {
        return _.keyBy(this.users, 'login')
      },

      entitiesOptions() {
        const waterBodies = new Set()
        const federalSubjects = new Set()
        this.plots.forEach((plot) => {
          waterBodies.add(plot.water_body)
          federalSubjects.add(plot.federal_subject)
        })
        return _.mapValues({ waterBodies, federalSubjects }, (items) =>
          [...items]
            .filter(Boolean)
            .sort()
            .map((value) => ({ value, text: value }))
        )
      },

      waterBodyOptions() {
        return [{ value: null, text: '[Не указана в данных участка]' }, ...this.entitiesOptions.waterBodies]
      },

      federalSubjectOptions() {
        return [{ value: null, text: '[Не указан в данных участка]' }, ...this.entitiesOptions.federalSubjects]
      },

      filteredPlots() {
        const filters = []

        if (this.filters.waterBody?.length) {
          filters.push(['water_body', (waterBody) => this.filters.waterBody.includes(waterBody)])
        }

        if (this.filters.federalSubject?.length) {
          filters.push(['federal_subject', (federalSubject) => this.filters.federalSubject.includes(federalSubject)])
        }

        let nameFilter = this.filters.name ? this.filters.name.trim() : null
        if (nameFilter) {
          nameFilter = nameFilter.toLowerCase()
          filters.push(['name', (name) => name && name.toLowerCase().includes(nameFilter)])
        }

        if (this.filters.user?.length) {
          filters.push(['user', (user) => this.filters.user.includes(user)])
        }

        if (!filters.length) {
          return this.plots
        }

        return this.plots.filter((plot) =>
          filters.every(([propertyName, predicate]) => predicate(propertyName ? plot[propertyName] : plot))
        )
      }
    },

    watch: {
      filteredPlots: {
        handler() {
          this.tableSettings.page = 1
        },
        deep: true
      }
    },

    created() {
      this.$auth.$on('logout', () => {
        this.fetchPlots()
        this.tableSettings = _.cloneDeep(defaultTableSettings)
        this.filters.user = []
      })

      this.fetchPlots()

      if (this.isAdmin) {
        this.fetchUsers()
        this.tableSettings = _.cloneDeep(adminDefaultTableSettings)
      }
    },

    methods: {
      formatDateTime,

      formatArea(value) {
        return formatNumber(value, plotPropertiesPrecision.area)
      },

      @handlePendingTask()
      async fetchPlots() {
        this.plots = await this.$api.backend.plots.get_plots()
      },

      async fetchUsers() {
        const result = await this.$api.backend.users.get_users()
        this.users = result.items
      },

      // plot.user may contain the existing user's login
      getOrganizationName(plotUser) {
        const user = this.usersIndexByLogin[plotUser]
        if (!user) {
          return plotUser
        }

        return user.full_name ?? user.short_name ?? plotUser
      }
    }
  }
</script>

<style lang="scss" scoped>
  .plots-list {
    .v-form {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      column-gap: 16px;
    }
  }
</style>
