import { render, staticRenderFns } from "./PlotsListView.vue?vue&type=template&id=37ba4a0b&scoped=true&"
import script from "./PlotsListView.vue?vue&type=script&lang=js&"
export * from "./PlotsListView.vue?vue&type=script&lang=js&"
import style0 from "./PlotsListView.vue?vue&type=style&index=0&id=37ba4a0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ba4a0b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VDataTable,VForm,VIcon,VSelect,VTextField})
