var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{staticClass:"plots-list",attrs:{"title":_vm.$t('pages.titles.plotsList')}},[_c('v-form',[_c('v-text-field',{attrs:{"label":"Идентификатор","dense":"","filled":"","background-color":"white"},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}}),(_vm.isAdmin)?_c('v-autocomplete',{attrs:{"label":"Организация","items":_vm.userOptions,"multiple":"","chips":"","small-chips":"","dense":"","filled":"","background-color":"white"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?[_vm._v(" "+_vm._s(("выбрано " + (_vm.filters.user.length) + " из " + (_vm.userOptions.length)))+" ")]:_vm._e()]}}],null,false,687705239),model:{value:(_vm.filters.user),callback:function ($$v) {_vm.$set(_vm.filters, "user", $$v)},expression:"filters.user"}}):_vm._e(),_c('v-select',{attrs:{"label":"Акватория","items":_vm.waterBodyOptions,"multiple":"","dense":"","filled":"","background-color":"white"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?[_vm._v(" "+_vm._s(("выбрано " + (_vm.filters.waterBody.length) + " из " + (_vm.waterBodyOptions.length)))+" ")]:_vm._e()]}}]),model:{value:(_vm.filters.waterBody),callback:function ($$v) {_vm.$set(_vm.filters, "waterBody", $$v)},expression:"filters.waterBody"}}),_c('v-select',{attrs:{"label":"Регион","items":_vm.federalSubjectOptions,"multiple":"","dense":"","filled":"","background-color":"white"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?[_vm._v(" "+_vm._s(("выбрано " + (_vm.filters.federalSubject.length) + " из " + (_vm.federalSubjectOptions.length)))+" ")]:_vm._e()]}}]),model:{value:(_vm.filters.federalSubject),callback:function ($$v) {_vm.$set(_vm.filters, "federalSubject", $$v)},expression:"filters.federalSubject"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableConfiguration,"items":_vm.filteredPlots,"options":_vm.tableSettings,"footer-props":{ itemsPerPageOptions: [15, 30, 50, 100] },"loading":_vm.isPending,"multi-sort":""},on:{"update:options":function($event){_vm.tableSettings=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.plotPropertiesFormatters.name(value))+" ")]}},{key:"item.area_ha",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatArea(value))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("aquarius.plotStatus." + value)))+" ")]}},{key:"item.user",fn:function(ref){
var value = ref.value;
return [(_vm.usersIndexByLogin[value])?_c('a',{attrs:{"href":("/user/profile/" + (_vm.usersIndexByLogin[value].id)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getOrganizationName(value))+" ")]):_c('span',{attrs:{"title":value}},[_vm._v(_vm._s(_vm.getOrganizationName(value)))])]}},{key:"item.water_body",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.plotPropertiesFormatters.water_body(value))+" ")]}},{key:"item.federal_subject",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.plotPropertiesFormatters.federal_subject(value))+" ")]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$bus.emit('printPlot', item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }